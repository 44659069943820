/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;
@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap");
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";
@import "primeicons/primeicons.css";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Nunito", sans-serif !important;
  background-color: #fafbff;
}

.custom-dialog-0 {
  .dialog-title {
    & {
      padding: 15px;
    }

    h2 {
      margin: 0;
      color: var(--gray-900, #101828);
      font-size: 18px;
      font-weight: 500;
      text-transform: capitalize;
      line-height: 1.2;
    }

    p {
      margin: 0;
      color: var(--gray-500, #667085);
      font-size: 14px;
      line-height: 20px;
    }

    .hr-custom {
      border: 0;
      border-bottom: 1px solid #eaecf0;
      margin-top: 10px;
      margin-left: -24px;
      margin-right: -24px;
    }
  }

  .dialog-body {
    padding: 16px;
  }

  .dialog-footer {
    & {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 16px;
      padding-bottom: 10px;
      padding-top: 0;
    }

    .hr-custom {
      border: 0;
      border-bottom: 1px solid #eaecf0;
      margin-left: -24px;
      margin-right: -24px;
    }

    .bt-div {
      & {
        display: flex;
        gap: 10px;
        align-self: flex-end;
      }

      .save-btn {
        border-radius: 8px;
        border: 1px solid #7f56d9;
        background: #7f56d9;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--white, #fff);
        font-size: 14px;
        font-weight: 600;
      }

      .reset-btn {
        border-radius: 8px;
        border: 1px solid #d0d5dd;
        background: var(--white, #fff);
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        color: var(--gray-700, #344054);
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

.item-table {
  thead tr th {
    background-color: inherit;
  }

  tbody tr td {
    padding-bottom: 15px;
  }

  tbody tr td:first-child {
    padding-top: 0px;
  }

  tbody tr:last-child td {
    border-bottom: 0;
  }
}

.confirm-card .card-body {

  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul {
    padding-left: 0em;
  }

  ul ul li {
    padding-left: 1em;
    border: 1px solid black;
    border-width: 0 0 1px 1px;
  }

  li.container-1 {
    border-bottom: 0px;
  }

  li.empty-1 {
    font-style: italic;
    color: silver;
    border-color: silver;
  }

  li p {
    margin: 0;
    background: white;
    position: relative;
    top: 0.9em;
  }

  ul ul li ul {
    border-top: 1px solid black;
    margin-left: -1em;
    padding-left: 2em;
  }

  ul ul li:last-child ul {
    border-left: 1px solid white;
    margin-left: -17px;
  }
}

.note-card {
  .list {
    font-size: 12px;
  }
}

.total-table thead tr th {
  background-color: inherit;
}

.total-table thead tr th:first-child {
  width: 65%;
}

.total-table thead tr th:last-child {
  text-align: end;
}

.total-table tbody tr td {
  background-color: inherit;
}

.total-table tbody tr td:first-child {
  width: 65%;
}

.total-table tbody tr td:last-child {
  text-align: end;
}